const APPCONFIG = {
    API_BASE_URL: process.env.REACT_APP_ENV_BASE_URL,
    IPFS_ENDPOINT: "https://ipfs.infura.io:5001",
    IPFS_URL: "https://cloudax.infura-ipfs.io/ipfs/",
    APP_NETWORK: process.env.REACT_APP_NETWORK,
    APP_NETWORK_CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
    APP_NETWORK_NET_ID: process.env.REACT_APP_NET_ID,
    INFURA_URL: process.env.REACT_APP_INFURA_URL
  };
  
  export default APPCONFIG;