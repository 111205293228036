import { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import APPCONFIG from "../constants/Config";

const useDataFromContractFunction = (contractAddress, abi, methodName, args = []) => {
  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);


  const fetchData = useCallback( async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const result = await contract[methodName](...args);
      setData(result);
      setDataLoading(false);
    } catch (err) {
      setDataError(err.message);
    }
    setDataLoading(false);
  }, [contractAddress, abi, methodName, args])




  useEffect(() => {
    fetchData();
  }, []);

  return { data, dataError, dataLoading };
};

export default useDataFromContractFunction;
