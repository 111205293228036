import { createSlice } from "@reduxjs/toolkit";
// Create slice (app)
export const appSlice = createSlice({
  name: "app",
  initialState: {
    assets: [],
  },
  reducers: {
    addAsset(state, action) {
      state.assets = action.payload;
    },
  },
});

export const { addAsset } = appSlice.actions;
