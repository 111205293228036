import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import { MoralisProvider } from "react-moralis";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Explorer from "./pages/Explorer.js";
import Staking from "./pages/Staking";
import Rewards from "./pages/Rewards";
import Insight from "./pages/Insight";
import Info from "./pages/Info";
import Info1 from "./pages/Info1";
import store from "./store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;
const appId = process.env.REACT_APP_MORALIS_APP_ID;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <MoralisProvider serverUrl={serverUrl} appId={appId}> */}
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/" element={<App />} />
            <Route path="/vesting" element={<Explorer />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/insight" element={<Insight />} />
            <Route path="/private-sale" element={<Info sale={1} />} />
            <Route path="/public-sale" element={<Info1 />} />
          </Routes>
        </BrowserRouter>
      {/* </MoralisProvider> */}
    </Provider>
  </React.StrictMode>
);
