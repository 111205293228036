// import Moralis from "moralis";

import {ethers} from "ethers";

export const toEther = (amount) => {
  if (typeof amount === "string") amount = ethers.BigNumber.from(amount);
  return ethers.utils.formatEther(amount);
  // return ethers.utils.formatEther(amount.toString());
};


export const isSuccessfulTransaction = (transaction) => new Promise((resolve, reject) => {
  const check = async () => {
    const receipt = await transaction.wait(1);
    if (receipt.status === 1) {
      return resolve(true)
    };
    setTimeout(check, 500).unref()
  }
  check()
});
