import { useState } from "react";
import Swal from "sweetalert2";
import { isSuccessfulTransaction } from "../utils/web3";
import BouncingDotsLoader from "./BouncingDotsLoader";
import { toEther } from "../utils/web3";
// import ApproveAmount from "./ApproveAmount";
import useGetBEP20Balance from "../hooks/useGetBEP20Balance";
import { inThousands } from "../utils/modifiers";

export default function BuyPresalev2({ launchpadState, launchpadHelpers }) {
  const {
    approveBUSD,
    buyLaunchpadSale: buyPresale,
    loadLaunchpad: reloadLaunchpad,
    loadUserVestingSchedules: reloadVesting,
  } = launchpadHelpers;
  const {
    launchpadSale
  } = launchpadState;
  const busdBalance = useGetBEP20Balance({
    address: process.env.REACT_APP_BUSD_ADDRESS,
  });
  const [presaleAmount, setPresaleAmount] = useState("");
  const [buying, setBuying] = useState(false);
  const [approveState, setApproveState] = useState("Approve & Swap");
  const numberishAmount = parseFloat(presaleAmount);


  const approve = async (e) => {
    e.preventDefault()
    setBuying(true);
    setApproveState("Approving");
    const saleMinFloat = parseFloat(launchpadSale.saleMin);
    const saleMaxFloat = parseFloat(launchpadSale.saleMax);
    if (launchpadSale) {
      if (numberishAmount < saleMinFloat) {
        Swal.fire({
          icon: "error",
          text: `${presaleAmount} USDC must be greater than or equal to sale min.`,
          title: "Amount less than minimum sale!",
        });
        setBuying(false);
        return true
      }
      if (numberishAmount > saleMaxFloat) {
        Swal.fire({
          icon: "error",
          text: `${presaleAmount} USDC must be less than or equal to sale max.`,
          title: "Amount more than maximum sale!",
        });
        setBuying(false);
        return true
      }
    }
    try {
      const success = await approveBUSD(presaleAmount);
      if (success) {
        setApproveState("Approved");
        const { isConfirmed } = await Swal.fire({
          text: `You have approved ${presaleAmount} USDC for swapping.`,
          title: "Approved!",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Complete Swap",
        })
        if (isConfirmed) {
          await buy();
          // add transistion css
        }
      }
    } catch (e) {
      console.error(e)
      Swal.fire({
        icon: "error",
        text: e.message,
        title: "Something went wrong!",
      });

      setApproveState("Approve & Swap");
    }

    setBuying(false);

    async function buy() {
      try {
        setBuying(true);
        setApproveState("Approved & Swapping");
        const transaction = await buyPresale(presaleAmount);
        const isSuccessful = await isSuccessfulTransaction(transaction);
        if (isSuccessful) {
          Swal.fire({
            text: "You have successfully swapped USDC for CLOUDAX.",
            title: "Transaction Successful!",
            icon: "success",
            confirmButtonText: "Cool",
          });
          setPresaleAmount("");
          setBuying(false);
          await reloadLaunchpad();
          await reloadVesting();
          setApproveState("Approve & Swap");
        }
      } catch (e) {
        console.log(e.message);
        Swal.fire({
          icon: "error",
          // text: e.message.error,
          text: "Your current purchase will exceed your maximum presale purchase cap",
          title: "Swap error!",
        });
        setBuying(false);
      }
    }

  };

  function prettifyBalance(amount) {
    return inThousands(
      Number(parseFloat(toEther(amount || "0"))).toFixed(2)
    )
  }


  return (
    <div className="w-full mt-8 lg:mt-10">
      <p>How much do you want to buy ?</p>
      <form className="flex flex-col w-full gap-5 mt-1" onSubmit={async (e) => await approve(e)}>
        <input
          disabled={buying}
          value={presaleAmount}
          onChange={(e) => setPresaleAmount(e.target.value)}
          onInput={(e) => setPresaleAmount(e.target.value)}
          className="w-full bg-[#00000000] text-white rounded-3xl pl-4 border border-[#ffffff50] h-[3rem]" type='number' placeholder='Enter USDC amount ' />

        <button type="submit"
          disabled={buying}
          className="grow h-[3rem] bg-gradient-to-r  hover:bg-gradient-to-l from-[#3636fa] to-[#e53affc0] rounded-3xl"
        >
          {approveState} {approveState === "Approve" ? "USDC" : ""}{" "}{buying && <BouncingDotsLoader />}
        </button>
      </form>
      <p className="text-center mt-2">Balance:&nbsp;
        {prettifyBalance(busdBalance)}&nbsp;
        USDC</p>
    </div>
  );
}
