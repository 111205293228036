import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = "http://localhost:7001";

// Uncomment when API is available
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/api`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (build) => ({
    register: build.mutation({
      query: (args) => ({
        url: "/register",
        method: "POST",
        body: {
          ...args,
        },
      }),
    }),
    updateAvatar: build.mutation({
      query: (args) => ({
        url: "/profile/update-avatar",
        method: "POST",
        body: {
          ...args,
        },
      }),
    }),
    getProfile: build.query({
      query: (args) => ({
        url: "/profile/@me",
      }),
    }),
  }),
});

export const { useRegisterMutation } = api;
