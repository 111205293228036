
import Layout from "../layout/Layout";
import Launch from "../components/sections/Launch";
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useHistory } from 'react-router-dom';

function App() {

  const [token, setToken] = useState('');
  const [address, setAddress] = useState('');
  const [data, setData] = useState({});
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  // const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      getAddress(token);
    } else {
      // history.push('/login');
    }
  }, []);

  async function getAddress(token) {
    const response = await fetch('/protected', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setAddress(data.address);
    } else {
      // history.push('/login');
    }
  }

  return (
    <Layout>
      <Launch sale={1} />
    </Layout>
  );
}

export default App;
