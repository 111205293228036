import Layout from "../layout/Layout"
import Number from "../components/Number"




const Rewards = () => {

    const Card = ({ index, purchased, address, reward, date }) => {
        return (
            <div className="flex justify-between bg-[#161616] h-[4rem] text-[#ffffff7c] items-center border-[#ffffff31] border-b">
                <p className="w-[5rem] md:w-[10%] shrink-0 ml-[1rem]">{index}</p>
                <p className="w-[15rem] md:w-[35%] shrink-0 text-[#2F79F9]">{address}</p>
                <p className="w-[10rem] md:w-[15%] shrink-0">{purchased}</p>
                <p className="w-[10rem] md:w-[15%] shrink-0">{reward}</p>
                <p className="w-[10rem] md:w-[15%] shrink-0">{date}</p>
            </div>
        )
    }


    const data = [
        // {
        //     // address : 'hdhhddhdhdhhdhdhdhd',
        //     // purchased : 'hhfhfhfhf',
        //     // reward : 'hdhdhdhdh',
        //     // date : '02/12/1888',
        // },
        // {
        //     address : 'hdhhddhdhdhhdhdhdhd',
        //     purchased : 'hhfhfhfhf',
        //     reward : 'hdhdhdhdh',
        //     date : '02/12/1888',
        // }
    ]

    const content = [
        {
            image: require('../images/icons/Subtract.png'),
            h1: 'Share your referral link',
            note: 'Copy your referral link and share with your friends to invite them to cloudax pre-sale.'
        },
        {
            image: require('../images/icons/2.png'),
            h1: 'Earn Reward',
            note: 'When  your friends purchase $CLDX on private or public sale. You will earn 1% of their purchased amount.  '
        },
        {
            image: require('../images/icons/3.png'),
            h1: 'Claim your reward',
            note: 'You would be able to claim your rewards from your dashboard when cloudax is listed.'
        }
    ]

    return (
        <Layout>
            <div className="flex justify-between flex-wrap gap-y-5">
                <div className="w-full md:w-[48%] flex justify-between flex-wrap gap-y-5">
                    <div className="bg-[#161616] py-4 px-5 rounded-3xl whitespace-nowrap shrink-0 h-fit w-full md:w-[47%] lg:w-[47%]">
                        <p className="lg:text-xl text-[#ffffff7c]">Total Earnings</p>
                        <p className="text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-bold">0 CLDX</p>
                    </div>
                    <div className="bg-[#161616] py-4 px-5 shrink-0 rounded-3xl h-fit w-full md:w-[47%] lg:w-[47%]">
                        <p className="lg:text-xl text-[#ffffff7c]">Payouts</p>
                        <p className="text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-bold">0 CLDX</p>
                    </div>
                    <div className="bg-[#161616] py-4 px-5 shrink-0 rounded-3xl h-fit w-full md:w-[47%] lg:w-[47%]">
                        <p className="lg:text-xl text-[#ffffff7c]">Refferals</p>
                        <p className="text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-bold">0</p>
                    </div>
                    <div className="bg-[#161616] py-4 px-5 shrink-0 rounded-3xl h-fit w-full md:w-[47%] lg:w-[47%]">
                        <p className="lg:text-xl text-[#ffffff7c]">Transactions</p>
                        <p className="text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-bold">0</p>
                    </div>
                </div>
                <div className="bg-[#161616] w-full md:w-[50%] h-[20rem] md:h-[initial] rounded-3xl"></div>
            </div>

            <div className="mt-[3rem] overflow-x-auto">
                <p className="text-[#ffffff7c] mb-4">Earning History</p>
                <div className="w-[50rem] md:w-full">
                    <div className="flex justify-between bg-[#161616] h-[4rem] text-[#ffffff7c] items-center border-[#ffffff31] rounded-t-3xl border w-full">
                        <p className="w-[5rem] md:w-[10%] shrink-0 ml-[1rem]">#</p>
                        <p className="w-[15rem] md:w-[35%] shrink-0">Address</p>
                        <p className="w-[10rem] md:w-[15%] shrink-0">Purchased</p>
                        <p className="w-[10rem] md:w-[15%] shrink-0">Reward</p>
                        <p className="w-[10rem] md:w-[15%] shrink-0">Date</p>
                    </div>
                    {data.length > 0 ? data.map(({ address, date, reward, purchased }, index) => <Card
                        address={address} date={date} reward={reward} purchased={purchased} index={index + 1}
                    />) : <div className="flex items-center justify-center bg-[#161616] h-[20rem]">
                        <p className="text-[#ffffff7c]">No history yet</p>
                    </div>}
                </div>
            </div>

            <div className="mt-[4rem]">
                <p className="text-xl font-black">3 Easy Steps</p>
                <h3 className="text-[#F7B34C] mb-[3rem] text-[1.8rem] md:text-[2.5rem] landing">To Earn Pre-sale Reward</h3>
                <div className="flex w-full flex-col md:flex-row flex-wrap md:justify-between gap-y-9 md:items-start">
                    {content.map((item, index) => <Number key={index} item={item} />)}
                </div>
            </div>
        </Layout>
    )
}

export default Rewards