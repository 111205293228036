import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../services/api";
import APPCONFIG from "../../constants/Config";
import Cookies from "js-cookie";
import { ethers } from "ethers";
import { validateNetworkChainId, postData, setAuth, signInNow } from "../../function/onChain/authFunction";
// const activeChainId = "0x" + APPCONFIG.APP_NETWORK_CHAIN_ID;
const activeChainId = "0xaa36a7"
const { API_BASE_URL } = APPCONFIG;


export const register = createAsyncThunk("auth/register", async (data) => {
 if (!window.ethereum) {
      alert("Install Metamask to effectively use the platform")
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const { chainId, name } = await provider.getNetwork();
  const res = await validateNetworkChainId();
  // if (res === false) 
  // return { msg: "Only " + APPCONFIG.APP_NETWORK + " addresses are allowed" };
  
    if (!window.ethereum) {
      //check if Metamask wallet is not installed
      alert("You must install Metamask first");
      return { msg: "You must install Metamask first" };
    }

   const wallets = await window.ethereum.request
      ({
        method: "eth_requestAccounts",
      })
      
      var login = true;
      if (
        (APPCONFIG.APP_NETWORK === "mainnet" || name === "homestead") &&
        chainId === parseInt(APPCONFIG.APP_NETWORK_CHAIN_ID)
      ) {
        // Do nothing
        login = true;
      } else if (
        name !== APPCONFIG.APP_NETWORK ||
        chainId !== parseInt(APPCONFIG.APP_NETWORK_CHAIN_ID)
      ) {
        login = false;
      }

      if (login === false) {
        alert("Only " + APPCONFIG.APP_NETWORK + " addresses are allowed");
        return {
          msg: "Only " + APPCONFIG.APP_NETWORK + " addresses are allowed",
        };
      }

      var formData = {
        walletAddress: wallets[0],
      };
      const REQUEST_URL = "register";
      const url = API_BASE_URL + REQUEST_URL;

      const response = await postData(url, formData);
      const isConnected = true;
      //setting the cookies
      const authName = "authName";
      const cookieValue = {
        address: wallets,
        isConnected: isConnected,
      };
      // const cookieExpiration = 2; //Days
      // setAuth(authName, cookieValue, cookieExpiration);
      const profile = await signInNow(response.data.confirmation, wallets[0]);
      return await profile; 
 
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user'))  : null,
    token: sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('user')) : '',
    loading: false,
    initialized: false,
    error: "",
    success: ""
  },
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
      Cookies.set("token", action.payload);
    },

    loginUser(state, action) {
      state.authenticated = true;
      state.user = action.payload;
      state.loading = false;
    },
    logout(state, action) {
      api.util.resetApiState();
      Cookies.remove("token");
      Cookies.remove("user");
      state.authenticated = false;
      state.user = null;
    },
    stopLoading(state, action) {
      state.loading = false;
    },
    populateUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.success = true;
        state.loading = false;
        state.initialized = true;
        state.user = action.payload.address;
        sessionStorage.setItem("token", JSON.stringify(action.payload.token));
        sessionStorage.setItem("user", JSON.stringify(action.payload.address));
      })
      .addCase(register.rejected, (state, action) => {
        console.log(action)
        state.error = action.payload;
      });
  },
});

export const { saveToken, logout } = authSlice.actions;
