import Layout from "../layout/Layout"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"

const Insight = () => {
    const [check, setCheck] = useState('transfers')

    const Card = ({ signature, time, from, to, amount, type }) => {
        return (
            <div className="flex justify-between bg-[#161616] h-[4rem] items-center">
                <p className="w-[10rem] md:w-[20%] shrink-0 ml-[1rem] text-[#2F79F9]">{signature}</p>
                <p className="w-[5rem] md:w-[10%] shrink-0 ">{time}</p>
                <p className="w-[5rem] md:w-[10%] shrink-0">{type}</p>
                <p className="w-[10rem] md:w-[20%] shrink-0 text-[#2F79F9]">{from}</p>
                <p className="w-[10rem] md:w-[20%] shrink-0 text-[#2F79F9]">{to}</p>
                <p className="w-[5rem] md:w-[10%] shrink-0">{amount}</p>
            </div>
        )
    }

    const Flex = ({ h1, p1 }) => {
        return (
            <div className="flex justify-between mb-4 items-center text-[#ffffff7c]">
                <p>{h1}</p>
                <p>{p1}</p>
            </div>
        )
    }

    const data = [
        // {
        //     signature: 'HXnFNs.....hPhWCCXN',
        //     time: '2 minutes',
        //     type: 'SPL- Transfer',
        //     from: 'HXnFNs.....hPhWCCXN',
        //     to: 'HXnFNs.....hPhWCCXN',
        //     amount: '99.92',
        // },
    ]

    const Small = ({ h1, p1 }) => {
        return (
            <div className="bg-[#161616] py-4 px-5 rounded-3xl  shrink-0 h-fit w-[47%] lg:w-[47%]">
                <p className="lg:text-xl text-[#ffffff7c]">{h1}</p>
                <p className="text-[1.5rem] md:text-[2rem] lg:text-[2.3rem] font-bold">{p1}</p>
            </div>
        )
    }

    return (
        <Layout>
            <div className="flex w-full flex-wrap mb-[2rem] justify-between gap-y-5">
                <div className="p-[2rem] w-full md:w-[48%] bg-[#161616] rounded-3xl">
                    <p className="text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-bold mb-3">Market Overview</p>
                    <Flex h1='Initial Market Cap' p1='$2,236,000' />
                    <Flex h1='Total Supply' p1='CLDX 10,000,000,000' />
                    <Flex h1='Current Supply' p1='CLDX 559,000,000' />
                    <Flex h1='Whitepaper' p1='https://docs.cloudax.io' />
                </div>
                <div className="p-[2rem] w-full md:w-[50%] bg-[#161616] rounded-3xl">
                    <p className="text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-bold mb-3">Profile Summary</p>
                    <Flex h1='Token Symbol' p1='CLDX' />
                    <Flex h1='Token Address' p1='HXnWFNs.....hPhWCCXN' />
                    <Flex h1='Network' p1='ETH (ERC-20)' />
                    <Flex h1='Decimals' p1='18' />
                </div>
            </div>

            <div className="flex justify-between flex-wrap gap-y-5">
                <div className="w-full md:w-[48%] flex justify-between flex-wrap gap-y-5">
                    <Small h1='CLDX Price' p1='$0.004' />
                    <Small h1='Transactions' p1='0' />
                    <Small h1='Holders' p1='0' />
                    <Small h1='Trading Volume' p1='$0' />
                    <Small h1='TVL' p1='$0' />
                    <Small h1='Staked' p1='CLDX 0' />
                </div>
                <div className="bg-[#161616] w-full md:w-[50%] h-[20rem] md:h-[initial] rounded-3xl">
                    <p className="mt-4 ml-5 text-[#ffffff7c]">Chart</p>
                    <div className="flex items-center justify-center h-full w-full text-[#ffffff7c]">
                        <div className="mt-[-4rem]">
                            <FontAwesomeIcon className="text-center block w-full text-[2rem]" icon={faChartLine} />
                            <p>not availabe now check later</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-[3rem] bg-[#161616] text-[13px]">
                <div className="flex bg-black h-[3rem] items-center">
                    <button onClick={() => setCheck('transfers')} className={`h-full px-4 rounded-t-2xl ${check === 'transfers' && 'bg-[#161616]'}`}>Transfers</button>
                    <button onClick={() => setCheck('transactions')} className={`h-full px-4 rounded-t-2xl ${check === 'transactions' && 'bg-[#161616]'}`}>Transactions</button>
                    <button onClick={() => setCheck('holders')} className={`h-full px-4 rounded-t-2xl ${check === 'holders' && 'bg-[#161616]'} `}>Holders</button>
                </div>
                <p className="text-[#ffffff7c] py-4 pl-4">Latest 10 of total 1,000 transactions since 12 months</p>
                <div className="overflow-x-auto">
                    <div className="w-[50rem] md:w-full">
                        <div className="flex justify-between bg-[#d9d9d92e] h-[4rem] items-center rounded-2xl">
                            <p className="w-[10rem] md:w-[20%] shrink-0 ml-[1rem]">Signature</p>
                            <p className="w-[5rem] md:w-[10%] shrink-0">Time</p>
                            <p className="w-[5rem] md:w-[10%] shrink-0">Type</p>
                            <p className="w-[10rem] md:w-[20%] shrink-0">From</p>
                            <p className="w-[10rem] md:w-[20%] shrink-0">To</p>
                            <p className="w-[5rem] md:w-[10%] shrink-0">Amount</p>
                        </div>
                        {data.length > 0 ? data.map(({ signature, time, type, amount, from, to }, index) => <Card key={index}
                            signature={signature} time={time} type={type} amount={amount} from={from} to={to}
                        />) : <div className="flex items-center justify-center bg-[#161616] h-[20rem]">
                            <p className="text-[#ffffff7c]">No history yet</p>
                        </div>}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Insight