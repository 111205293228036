import Layout from "../layout/Layout"
import Announcement from "../components/sections/Announcement"

const Explorer = () => {
    return (
        <Layout>
            <div>Vesting</div>
            <Announcement/>
        </Layout>
    )
}


export default Explorer