import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { appSlice } from "./slice/appSlice";
import { authSlice } from "./slice/authSlice";
import thunk from "redux-thunk";
import { api } from "./services/api";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authSlice.reducer,
    app: appSlice.reducer,
  },

  middleware: [api.middleware, thunk],
});

export const actions = {
  auth: authSlice.actions,
  app: appSlice.actions,
};
export default store;
