import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import truncateEthAddress from "truncate-eth-address";
import Modal from "./Modal";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import LoopingImages from "./LoopingImages";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logout, register } from "../store/slice/authSlice";
import ethers from "ethers";




export default function ConnectWallet() {
  const dispatch = useDispatch()
  const { user, loading, error } = useSelector((state) => state.auth);
 
  const login = async (options) => {

    dispatch(register())

  };


  
  useEffect(() => {
    // if(!user){
    //   return
    // }else{
    //   dispatch(register())
    // }
  }, [user]);
 
  return (
    <div>
        <div>
          <div className="flex items-center">
            <button
              onClick={async () =>
                user ? dispatch(logout()) : login() 
                //   isAuthenticated ? await logout() : toggle(true) 
              }
              className={`
                  ? "bg-[#D30000] h-[40px] md:h-[40px] px-3 sm:px-5 lg:px-0 min-w-[100px] lg:w-[170px] text-white text-xs sm:text-sm md:text-[18px] md:leading-[35px] flex justify-center items-center darker-red rounded-full"
                  : "btn2"
                }`}
            >
              {user ? truncateEthAddress(user) : "Connect Wallet"}
              
            </button>
          </div>
          
        </div>
    </div>
  );
}
