import { useState } from "react";
// import * as ethers from "ethers";
// import { BrowserProvider } from "ethers";
import { ethers } from "ethers";
import { useRegisterMutation } from "../../store/services/api";
import axios from "axios";
import APPCONFIG from "../../constants/Config";
const activeChainId = "0xaa36a7"
const { API_BASE_URL } = APPCONFIG;

export async function postData(url, data, token) {
  // try {
  if (!token) {
    try {
      const response = await axios.post(url, data);

      return response.data;
    } catch (error) {
      return error;
    }
  } else {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
}

export async function getData(url, token) {
  try {
    if (!token) {
      const response = await axios.get(url, token);
      return response.data;
    } else {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const SignInAuth = async () => {
  const authName = "auth.session";
  const authUser = checkAuth(authName);
  if (authUser == null) {
    return false;
  }
  return true;
};

export async function disconnectWallet() {
  // disconnect the first wallet in the wallets array
  ///deleting the cookies to logout user
  const authName = "authName";
  const cookieExpiration = 30; //Days
  const isAuth = checkAuth(authName);
  if (isAuth !== null && isAuth.length > 0) {
    const res = deleteAuth(authName);
  }
  const authSession = "auth.session";
  const isSignedIn = checkAuth(authSession);
  if (isSignedIn !== null && isSignedIn.length > 0) {
    const res = deleteAuth(authSession);
  }
  const jwtAuthName = "jwtAccess";
  const isJWTAuth = checkAuth(jwtAuthName);
  if (isJWTAuth !== null && isJWTAuth.length > 0) {
    const res = deleteAuth(jwtAuthName);
  }
  window.location.href = "/";
}

export async function validateNetworkChainId() {
  return window.ethereum
    .request({
      method: "eth_chainId",
    })
    .then((currentChainId) => {
      if (currentChainId !== activeChainId) {
        autoSwitchNetwork();
        // return false;
      }
      return true;
    });
}

export async function autoSwitchNetwork() {
  window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: activeChainId }],
  });
}

export async function connectUserWallet() {
  // const provider = new ethers.providers.JsonRpcProvider(APPCONFIG.INFURA_URL); 
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const { chainId, name } = await provider.getNetwork();
  const res = await validateNetworkChainId();
  if (res === false) return;
  try {
    if (!window.ethereum) {
      //check if Metamask wallet is not installed
      alert("You must install Metamask first");
      return { msg: "You must install Metamask first" };
    }

    await window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then((wallets) => {
        var login = true;
        authenticateWallet();
        if (
          (APPCONFIG.APP_NETWORK === "mainnet" || name === "homestead") &&
          chainId === parseInt(APPCONFIG.APP_NETWORK_CHAIN_ID)
        ) {
          // Do nothing
          login = true;
        } else if (
          name !== APPCONFIG.APP_NETWORK ||
          chainId !== parseInt(APPCONFIG.APP_NETWORK_CHAIN_ID)
        ) {
          login = false;
        }

        if (login === false) {
          alert("Only " + APPCONFIG.APP_NETWORK + " addresses are allowed");
          return {
            msg: "Only " + APPCONFIG.APP_NETWORK + " addresses are allowed",
          };
        }

        async function authenticateWallet() {
          var formData = {
            walletAddress: wallets[0],
          };
          const REQUEST_URL = "register";
          const url = API_BASE_URL + REQUEST_URL;

          const response = await postData(url, formData);
          if (response) {
            const isConnected = true;
            //setting the cookies
            const authName = "authName";
            const cookieValue = {
              address: wallets,
              isConnected: isConnected,
            };
            const cookieExpiration = 30; //Days
            setAuth(authName, cookieValue, cookieExpiration);
            signInNow(response.data.confirmation, wallets[0]);
          } else {
            console.error("Failed to register user");
            // Display an error message
          }
        }
      });
  } catch (error) {
    window.alert(error.message);
    // window.alert("You need to connect your wallet to continue.");
    return {
      msg: "You need to connect your wallet to continue.",
    };
  }
}

export async function signInNow(message, address) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);

    var formData = {
      signature: signature,
      walletAddress: address,
    };
    const REQUEST_URL = "verify";
    const url = API_BASE_URL + REQUEST_URL;
    const response = await postData(url, formData);
    if (response.error) {
      throw new Error(response.error);
    }else{
      const REQUEST_URL_PROFILE = "profile";
      const urlP = API_BASE_URL + REQUEST_URL_PROFILE;
      const profile = await getData(urlP, response.data.token);
      const payload = {
        address: address,
        token: response.data.token,
      }
      return payload;
    }
  } catch (err) {
    alert("Please you need to sign the transaction to continue!");
    return err.message;
  }
}


export function setAuth(authName, authValue, authExpirationInDays) {
  const d = new Date();
  d.setTime(d.getTime() + authExpirationInDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    authName + "=" + JSON.stringify(authValue) + ";" + expires + ";path=/";
}
function deleteAuth(authName) {
  //Logout
  const d = new Date();
  const authExpirationInDays = 365; // 1yr ago
  d.setTime(d.getTime() - authExpirationInDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = authName + "=;" + expires + ";path=/";
}
export function checkAuth(authName) {
  let user = getAuth(authName);
  user = user.length > 0 ? user : null;
  return user;
}
export function getAuth(authName) {
  let name = authName + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
