
const Announcement = () => {
    return (
        <div className="h-[4.5rem] w-full mt-4 flex items-center rounded-3xl gap-5 justify-center bg-gradient-to-r from-[#2F79F9] to-[#714efc] px-3 sm:px-0">

            <p className="text-white text-xl font-bold">Coming Soon </p>
        </div>
    )
}

export default Announcement