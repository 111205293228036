import { useEffect } from "react";
import useDataFromContractFunction from "./useDataFromContractFunction";
import IBEP20 from "../abis/IBEP20.json";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function useGetBEP20Balance({ address }) {
  const dispatch = useDispatch()
  const { user, loading, error } = useSelector((state) => state.auth);
  const { data, dataLoading, dataError } = useDataFromContractFunction(address, IBEP20, "balanceOf", [user]);
  
  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_BALANCE", payload: data.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataLoading, dataError, user, loading, error]);
  return data;
}